import { GuestConstraintRoute } from '@sharelo-lib/auth';
import { ShareloSeo } from '@sharelo-lib/seo';
import { AppLoading } from '@sharelo-lib/ui';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { Suspense } from 'react';

const LoginPageContainer = dynamic(
  () =>
    import('@sharelo-lib/auth-pages').then(
      (module) => module.LoginPageContainer,
    ),
  { ssr: false },
);

const Login = () => {
  const { t } = useTranslation();
  return (
    <Suspense fallback={<AppLoading />}>
      <ShareloSeo
        title={t('seo.title.login') as string}
        description={t('seo.description.default') as string}
      />
      <GuestConstraintRoute>
        <LoginPageContainer />
      </GuestConstraintRoute>
    </Suspense>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Login;
